import React, { useState } from "react"
import { navigate } from "gatsby"
import Photo from "../../images/consultor.png"
import client from "../../service/graphql"
import mutations from "../../service/mutations"

import {
  Container,
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  Input,
  Button,
  Loading,
  ContainerError,
  ErrorText
} from "./styles"
import { Body, Buttons } from "../../steps/shared-styles"
import HeaderProgress from "../../components/HeaderProgress"
import { formatNumber, formatAndExtractNumber } from "../../utils"

const CodeInvite = ({ deal, phone }) => {
  const [cpf, setCpf] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleAuthenticate = async () => {
    const { phone_country_code, phone_number } = formatAndExtractNumber(phone);
    await client.request(mutations.AUTHENTICATE, {
        input: {
          phone_country_code,
          phone_number,
        },
      });
      navigate('/signin-sms', {state: { deal, phone: phone_number }})
  };


  async function submit(e) {
    e.preventDefault()
    try {
      setLoading(true);
      const { authenticateCpf } = await client.request(mutations.AUTHENTICATE_CPF, {
        input: {
          cpf,
          password,
        },
      });
      const { token } = authenticateCpf;
      localStorage.setItem('@verizaLP:token', token);

      navigate('/finish-invite', { state: { deal } })
    }catch(errors){
      const code = errors?.response?.errors[0]?.message;
      setError(code);
    } finally{
      setLoading(false)
    }
  }

  return (
    <div>
      <HeaderProgress description="Sobre você" percent={50} />
      <Container>
        <form onSubmit={submit}>
          <Section>
            <Body>
              <Header>
                <Profile>
                  <User src={Photo} />
                </Profile>
                <Label><p>Entre em um grupo:</p></Label>
              </Header>
              <Form>
                <Input
                  placeholder="Digite o seu cpf"
                  onChange={({ target }) => setCpf(target.value)}
                  value={cpf}
                  mask="999.999.999-99"
                />

                <Input
                  placeholder="Digite sua senha"
                  onChange={({ target }) => setPassword(target.value)}
                  value={password}
                  type='password'
                />

                {error.length > 0 && (
                  <ContainerError>
                    <ErrorText>{error}</ErrorText>
                  </ContainerError>
                )}

                {loading && <Loading />}
              </Form>
            </Body>
            <Buttons>
              <Button type="submit" disabled={cpf?.length < 14 || password?.length < 1} >
                Próximo
              </Button>
            </Buttons>
          </Section>
        </form>
      </Container>
    </div>
  )
}

export default CodeInvite
