import React from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"

// Sections
import Login from "../sections/login"

function LoginScreen({ location }) {
  return (
    <Layout>
      <SEO title="Cadastro" />
      <Login phone={location.state?.phone} deal={location.state?.deal} />
    </Layout>
  );
}

export default LoginScreen;